
import { Component, Vue, Prop } from 'vue-property-decorator';
import { DisplayPriceInfo, ProductSearchResultProduct } from '../../models/Product';
import { toCssClassName } from '../../utils/StringUtils';
import { CbxVariablesModule } from '../../store/modules/CbxVariablesModule';
import UserModule from '@/store/modules/UserModule';

@Component({
    name: 'ProductCard',
})
export default class ProductCard extends Vue {
    @Prop({ type: Object, required: true }) private product: ProductSearchResultProduct;
    @Prop({ type: String, required: false, default: null }) private variantId: string;

    // add intro animation
    private rdy = false;
    private mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.rdy = true;
            }, 500);
        });
    }

    get isLoggedIn(): boolean {
        return UserModule.IS_SIGNED_IN;
    }

    get loginPageUrl(): string {
        return CbxVariablesModule.VARIABLES.loginpage;
    }

    get imageWidth(): number {
        return this.product.imagewidth || 400;
    }
    get imageHeight(): number {
        return this.product.imageheight || 300;
    }

    get translationsReady(): boolean {
        return this.$root.$data.translationsLoaded === true;
    }

    get enablePurchaseFromLists(): boolean {
        return CbxVariablesModule.VARIABLES?.enablePurchaseFromLists;
    }

    get allowPurchase(): boolean {
        return CbxVariablesModule.VARIABLES?.allowPurchase;
    }

    get secondaryImage(): string {
        if (!CbxVariablesModule.VARIABLES?.enableSecondaryImage || this.product.images.length <= 1) return null;

        return this.product?.images[1];
    }

    get price(): DisplayPriceInfo {
        if (this.product.bulkdiscounts?.length)
            return this.product.bulkdiscounts.sort((a, b) => {
                return a.priceexvatasnumeric - b.priceexvatasnumeric;
            })[0];

        return this.product?.price;
    }

    get onDiscount(): boolean {
		if (this.product?.bulkdiscounts?.filter((a) => a.ondiscount)?.length) return true;

        return this.product?.price?.ondiscount ?? false;
    }

    get showBulkInfo(): boolean {
        if (this.product?.bulkdiscounts?.length) return true;

        return false;
    }

    get url(): string {
        if (this.variantId?.length > 0)
            return `${this.product?.url}?variant=${this.variantId}`;

        return this.product?.url
    }

    private asLazyImage(img: string) {
        return 'data-src:' + img;
    }

    private getCssClassName(prefix: string, input: string) {
        return toCssClassName(prefix, input);
    }
}
